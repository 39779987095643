import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import Slick from "../components/Slick"
import { Section, SectionTitle } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Box } from "../components/CompoundComponents/Box"
import Footer from "../components/Footer/index"

const PoolPage = ({ data, intl }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи, Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган"
        description="Один из лучших санаториев Узбекистана - Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган, Ниҳол саломатлик сиҳатгоҳи"
      />
      <NavBar />
      <Section>
        <Columns>
          <Column is8>
            <Slick slideToShow={1} slideToScroll={1} speed={3000}>
              {data.pool.edges.map(item => (
                <Img
                  fluid={item.node.featuredImage.childImageSharp.fluid}
                  alt={item.node.id}
                />
              ))}
            </Slick>

            <Box white>
              <div className="content">
                <SectionTitle>
                  {" "}
                  {intl.formatMessage({
                    id: `pool.title`,
                  })}
                </SectionTitle>
                <h3 className="is-size-6-mobile">
                  {intl.formatMessage({
                    id: `pool.desc`,
                  })}
                </h3>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `pool.list.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `pool.list.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `pool.list.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `pool.list.item-4`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `pool.list.item-5`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `pool.list.item-6`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
            <Box white>
              <div className="content">
                <SectionTitle>
                  {" "}
                  {intl.formatMessage({
                    id: `jakuzzi.title`,
                  })}
                </SectionTitle>
                <p>
                  {intl.formatMessage({
                    id: `jakuzzi.desc`,
                  })}
                </p>
              </div>
            </Box>
            <Box white>
              <div className="content">
                <SectionTitle>
                  {" "}
                  {intl.formatMessage({
                    id: `finnish-sauna.title`,
                  })}
                </SectionTitle>
                <p>
                  {intl.formatMessage({
                    id: `finnish-sauna.desc`,
                  })}
                </p>
              </div>
            </Box>
            <Box white>
              <div className="content">
                <SectionTitle>
                  {" "}
                  {intl.formatMessage({
                    id: `turkish-sauna.title`,
                  })}
                </SectionTitle>
                <p>
                  {intl.formatMessage({
                    id: `turkish-sauna.desc`,
                  })}
                </p>
              </div>
            </Box>
          </Column>
          <Column is4>
            <article className="message is-warning is-shadow">
              <div className="message-body">
                <div className="content">
                  <h3>
                    {intl.formatMessage({
                      id: `pool.title`,
                    })}{" "}
                    (
                    {intl.formatMessage({
                      id: `paid.title`,
                    })}
                    )
                  </h3>
                  <p className="mb-1">
                    {intl.formatMessage({
                      id: `free.title`,
                    })}
                  </p>
                  <a
                    href="tel:+998988001121"
                    className="button hvr-bounce-to-right is-uppercase is-primary is-outlined has-text-weight-bold is-small has-family-pt-sans mr-05"
                  >
                    +998 98 800 1121
                  </a>
                </div>
              </div>
            </article>
            <article className="message is-primary is-shadow">
              <div className="message-body">
                <div className="content">
                  <h3>
                    {intl.formatMessage({
                      id: `pool.desc-1`,
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: `pool.desc-2`,
                    })}
                  </p>
                  <ul>
                    <li>
                      {intl.formatMessage({
                        id: `pool.list-1.item-1`,
                      })}
                    </li>
                    <li>
                      {intl.formatMessage({
                        id: `pool.list-1.item-2`,
                      })}
                    </li>
                    <li>
                      {intl.formatMessage({
                        id: `pool.list-1.item-3`,
                      })}
                    </li>
                  </ul>
                  <p>
                    {intl.formatMessage({
                      id: `pool.desc-3`,
                    })}
                  </p>
                </div>
              </div>
            </article>
            <article className="message is-danger is-shadow">
              <div className="message-body">
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.title`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-1`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-2`,
                  })}
                </p>
              </div>
            </article>
          </Column>
        </Columns>
      </Section>
      <Footer data={data.section.edges[0]} />
    </>
  )
}

export const PoolPageQuery = graphql`
  query {
    pool: allPoolYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    section: allProductsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(PoolPage)
